<template>
    <v-container>        
            <v-row>
                <v-col cols="12">
                    <v-data-table
                        :headers="headers"
                        :items="orders"    
                        class="elevation-1"    
                         
                    >   
                                            <template v-slot:top>
                        <v-toolbar
                            flat
                        >
                            <v-toolbar-title>Zamówienia Online</v-toolbar-title>
                            <v-divider
                            class="mx-4"
                            inset
                            vertical
                            ></v-divider>
                            <v-spacer></v-spacer>                              
                        </v-toolbar>
                        </template>                                
                      <template v-slot:item.actions="{ item }">
                        <v-btn class="primary" @click="showCalendar(item.id)"><v-icon                     small
                    class="pt-1"  >mdi-calendar</v-icon></v-btn>
                      </template>                                   
                    </v-data-table>
                </v-col>
            </v-row>                           
          <v-dialog fullscreen hide-overlay v-model="dialog">
            <v-card>
        <v-toolbar
          dark
          color="primary"
        >
          <v-btn
            icon
            dark
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Tworzenie Wydarzenia</v-toolbar-title>
          <v-spacer></v-spacer>

        </v-toolbar>    
        <v-container>          
            <v-row>
                <v-col cols="6">
                    <v-select></v-select>
                </v-col>
                <v-col cols="6">
                    <v-select></v-select>
                </v-col>
            </v-row>            
            <v-row >
                <v-col cols="12" style="height:650px;">
                    <FullCalendar style="height:650px;" :options="calendarOptions" />   
                </v-col> 
            </v-row>  
        </v-container>
            </v-card>          
          </v-dialog>            
    </v-container>
</template>
<script>
import '@fullcalendar/core/vdom' // solves problem with Vite
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import timeGridPlugin from '@fullcalendar/timegrid';
import plLocale from '@fullcalendar/core/locales/pl';

// import VuetifyGoogleAutocomplete from 'vuetify-google-autocomplete'
const dayjs = require('dayjs')
  export default {

    name:'Store',
    components:{FullCalendar},
    data: () => ({
calendarOptions: {
        plugins: [ dayGridPlugin, interactionPlugin,timeGridPlugin ],        
            headerToolbar: {
                left: 'prev,today,next',
                center: 'title',
                right: 'dayGridMonth,timeGridWeek,timeGridDay,timeGridFourDay'
            },
            views: {
                timeGridFourDay: {
                    type: 'timeGrid',
                    duration: {
                        days: 3
                    },
                    buttonText: '3 Dniowy'
                }
            },
            businessHours: {
                daysOfWeek: [1, 2, 3, 4, 5, 6, 7], // Monday - Thursday
                // days of week. an array of zero-based day of week integers (0=Sunday)                
                startTime: '06:00', // a start time (10am in this example)
                endTime: '22:00', // an end time (6pm in this example)
            },  
            displayEventTime: false,
            lang: 'pl',
            selectable: true,
            longPressDelay: 750,
            locale: plLocale,
            buttonIcons: false, // show the prev/next text
            allDaySlot: false,
            weekNumbers: false,
            draggable: false,
            disableDragging: false,
            disableResizing: false,
            initialView: 'timeGridFourDay',
            navLinks: true, // can click day/week names to navigate views
            editable: true,
            dayMaxEvents: true, // allow "more" link when too many events                  
      },         
    address: "",
    action:'',
    warehouseName:'',
      dialog: false,
      headers: [
        {
          text: 'Data Dodania',          
          sortable: false,
          value: 'addDate',
        },         
        {
          text: 'Id Zamówienia',
          align: 'start',
          sortable: true,
          value: 'OrderNo',
        },    
        {
          text: 'Dostawa',
          align: 'start',
          sortable: false,
          value: 'address',
        },   
        {
          text: 'Telefon',
          align: 'start',
          sortable: false,
          value: 'phone',
        },
        {
          text: 'Sposób Płatności',
          align: 'start',
          sortable: false,
          value: 'paymentType',
        },                                 
        { text: '', value: 'actions', sortable: false },         
      ],
      orders: [],      
    }),

    created () {
      this.initialize()
    },

    methods: {     
      showCalendar(id){
        console.log(id)
        this.dialog = true
        setTimeout(()=>{document.querySelector('.fc-timeGridFourDay-button').click();console.log('click')},300)
      }, 
      initialize () {
        this.orders = [
          {
            id:1,
            addDate:dayjs().format('YYYY-MM-DD HH:mm'),
            OrderNo:'40620',
            address: 'Wojska Polskiego',
            phone:'519835159',
            paymentType: 'Blik',
           
          },   
          {
            id:1,
            addDate:dayjs().format('YYYY-MM-DD HH:mm'),
            OrderNo:'40620',
            address: 'Wojska Polskiego',
            phone:'519835159',
            paymentType: 'Blik',
           
          },                                                        
        ]
      },
    },
  }
</script>