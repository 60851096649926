import Vue from 'vue'
import VueRouter from 'vue-router'
import Calendar from '../views/Calendar.vue'
import Login from '../views/Login.vue'
import Warehouse from '../views/Warehouse.vue'
import Store from '../views/Store.vue'
import Payments from '../views/Payments.vue'
import Invoice from '../views/Invoice.vue'
import Clients from '../views/Clients.vue'
import Users from '../views/Users.vue'
import Day from '../views/Day.vue'
import DayRaport from '../views/Daily-raport.vue'
import Service from '../views/Service.vue'
import Raports from '../views/Raports.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/calendar',
    name: 'Calendar',
    component: Calendar
  },
  {
    path: '/raports',
    name: 'Raports',
    component: Raports
  },  
  {
    path: '/daily-raport',
    name: 'DayRaport',
    component: DayRaport
  },  
  {
    path: '/service',
    name: 'Service',
    component: Service
  },   
  {
    path: '/day',
    name: 'Day',
    component: Day
  },  
  {
    path: '/clients',
    name: 'Clients',
    component: Clients
  },  
  {
    path: '/users',
    name: 'Users',
    component: Users
  },    
  {
    path: '/store',
    name: 'Store',
    component: Store
  },  
  {
    path: '/payments',
    name: 'Payments',
    component: Payments
  },  
  {
    path: '/invoice',
    name: 'Invoice',
    component: Invoice
  },  
  {
    path: '/warehouse',
    name: 'Warehouse',
    component: Warehouse
  },  
  {
    path: '/login',
    name: 'Login',
    component: Login
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  if (to.name !== 'Login' && localStorage.getItem('user')!='true') next({ name: 'Login' })
  else next()
})
export default router
