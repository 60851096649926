<template>
         <v-container>
            <v-row>
                <v-col cols="12">  
                    <v-sheet  color="grey lighten-3">    
                        <v-sheet
            :elevation="2"
            rounded>              
                    <v-row class="pa-5">
                        <v-col cols="8">
                            <v-text-field
                                label="Numer Umowy"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="4">
                            <v-btn class="primary" style="width:100%"><v-icon>mdi-account-search</v-icon></v-btn>
                        </v-col>                          
                        <v-col cols="8">
                            <v-text-field
                                label="Nazwisko"
                            ></v-text-field>                            
                        </v-col>
                        <v-col cols="4">
                            <v-btn class="primary" style="width:100%"><v-icon>mdi-account-search</v-icon></v-btn>
                        </v-col>                          
                        <v-col cols="8">
                                                        <v-text-field
                                label="Adres"
                            ></v-text-field>  
                        </v-col>
                        <v-col cols="4">
                            <v-btn class="primary" style="width:100%"><v-icon>mdi-account-search</v-icon></v-btn>
                        </v-col>                                                                        
                    </v-row>    
                        </v-sheet>
                    </v-sheet>                
                </v-col>    
                <v-spacer></v-spacer>  
 <v-col cols="12">                    
                    <v-data-table
                        :headers="headers"
                        :items="products"    
                        class="elevation-1"    
                          mobile-breakpoint="0"   
                    >     
                                            <template v-slot:top>
                        <v-toolbar
                            flat
                        >
                            <v-toolbar-title>Baza Sprzętu</v-toolbar-title>
                            <v-divider
                            class="mx-4"
                            inset
                            vertical
                            ></v-divider>
                            <v-spacer></v-spacer>                              
                        </v-toolbar>
                        </template>                            
                      <template v-slot:item.actions="{ item }">  
                        <v-btn class="info" @click="showHistory(item.id)"><v-icon                     small
                    class="pt-1"  >mdi-file-image</v-icon></v-btn>                                        
                      </template>                                                           
                    </v-data-table>
                </v-col>   
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar
          dark
          color="primary"
        >
          <v-btn
            icon
            dark
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Galeria</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>  
        <v-container>
    <v-row>
    <v-col
      v-for="n in 9"
      :key="n"
      class="d-flex child-flex"
      cols="4"
    >
      <v-img
        :src="`https://picsum.photos/500/300?image=${n * 5 + 10}`"
        :lazy-src="`https://picsum.photos/10/6?image=${n * 5 + 10}`"
        aspect-ratio="1"
        class="grey lighten-2"
      >
        <template v-slot:placeholder>
          <v-row
            class="fill-height ma-0"
            align="center"
            justify="center"
          >
            <v-progress-circular
              indeterminate
              color="grey lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
      </v-img>
    </v-col>
  </v-row>     
        </v-container>
      </v-card>
    </v-dialog>                                                                          
    </v-row>                  
    </v-container>
</template>
<script>
  export default {

    name:'Service',    
    data: () => ({ 
        dialog:false, 
      headers: [       
        {
          text: 'Numer Umowy',
          align: 'start',
          sortable: false,
          value: 'dealNo',
        },    
        {
          text: 'Nazwisko',
          align: 'start',
          sortable: false,
          value: 'lastName',
        },   
        {
          text: 'Adres',
          align: 'start',
          sortable: false,
          value: 'address',
        }, 
        {
          text: 'Sprzęt',
          align: 'start',
          sortable: false,
          value: 'product',
        },                                                         
        { text: '', value: 'actions', sortable: false },         
      ],
      products: [],                  
    }),

    created () {
      this.initialize()
    },

    methods: {     
      initialize () {
        this.products = [
          {
            id:1,
            dealNo:'BD010101',
            lastName:'Sowiński',
            address: 'Wojska Polskiego',            
            product:'Łóżko',            
          }]        
      },
      showHistory(){
        this.dialog = true
      }
    },
  }
</script>