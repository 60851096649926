<template>
    <v-container>        
            <v-row>
                <v-col cols="12">  
                    <v-sheet  color="grey lighten-3">    
                        <v-sheet
            :elevation="2"
            rounded>              
                    <v-row class="pa-5">
                        <v-col cols="8">
                            <v-text-field
                                label="Numer Umowy"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="4">
                            <v-btn class="primary" style="width:100%"><v-icon>mdi-account-search</v-icon></v-btn>
                        </v-col>                          
                        <v-col cols="8">
                            <v-text-field
                                label="Nazwisko"
                            ></v-text-field>                            
                        </v-col>
                        <v-col cols="4">
                            <v-btn class="primary" style="width:100%"><v-icon>mdi-account-search</v-icon></v-btn>
                        </v-col>                          
                        <v-col cols="8">
                                                        <v-text-field
                                label="Adres"
                            ></v-text-field>  
                        </v-col>
                        <v-col cols="4">
                            <v-btn class="primary" style="width:100%"><v-icon>mdi-account-search</v-icon></v-btn>
                        </v-col>                                                                        
                    </v-row>    
                        </v-sheet>
                    </v-sheet>                
                </v-col>
                <v-spacer></v-spacer>
                <v-col cols="12">                    
                    <v-data-table
                        :headers="headers"
                        :items="payments"    
                        class="elevation-1"    
                          mobile-breakpoint="0"   
                    >     
                                            <template v-slot:top>
                        <v-toolbar
                            flat
                        >
                            <v-toolbar-title>Płatności</v-toolbar-title>
                            <v-divider
                            class="mx-4"
                            inset
                            vertical
                            ></v-divider>
                            <v-spacer></v-spacer>                              
                        </v-toolbar>
                        </template>                            
                      <template v-slot:item.actions="{ item }">
                        <v-btn class="primary" @click="showDialog(item.id)"><v-icon                     small
                    class="pt-1"  >mdi-plus</v-icon></v-btn>
                        <v-btn class="warning" @click="showInvoice(item.id)"><v-icon                     small
                    class="pt-1"  >mdi-receipt-text</v-icon></v-btn>  
                        <v-btn class="info" @click="showHistory(item.id)"><v-icon                     small
                    class="pt-1"  >mdi-history</v-icon></v-btn>                                        
                      </template>                                                           
                    </v-data-table>
                </v-col>
            </v-row>         
          <v-dialog v-model="invoiceDialog"       width="500">
            
                <v-card>
<v-toolbar
              color="primary"
              dark
            >Dane do Faktury</v-toolbar>
            <v-card-actions class="justify-end">
            </v-card-actions>  
            <v-container>                  
                    <v-form>
                        <v-row>
                            <v-col cols="12">
                                <v-textarea                                    
                                    label="Dane do Faktury"
                                >

                                </v-textarea>
                            </v-col>                                                                                                                   
                        </v-row>
                         
                    </v-form>
                    </v-container> 
                    <v-card-actions>
              <v-btn
                text                
                @click="invoiceDialog = false"
              >Anuluj</v-btn> 
               <v-spacer></v-spacer>
          <v-btn            
            text
            @click="savePayment()"
          >
            Zapisz
          </v-btn>     
                    </v-card-actions>                            
                </v-card>  
                   
          </v-dialog>
          <v-dialog v-model="historyDialog"       width="500">
            
                <v-card>
<v-toolbar
              color="primary"
              dark
            >Historia Płatności</v-toolbar>
            <v-card-actions class="justify-end">
            </v-card-actions>  
            <v-container>                  
                    <v-data-table
                        :headers="headersHistory"
                        :items="history"    
                        class="elevation-1"    
                          mobile-breakpoint="0"   
                    > 
                    </v-data-table>
            </v-container> 
                    <v-card-actions>
              <v-btn
                text                
                @click="historyDialog = false"
              >Anuluj</v-btn>                    
                    </v-card-actions>                            
                </v-card>  
                   
          </v-dialog>                                              
          <v-dialog v-model="dialog"       width="500">
            
                <v-card>
<v-toolbar
              color="primary"
              dark
            >Dodawanie płatności</v-toolbar>
            <v-card-actions class="justify-end">
            </v-card-actions>  
            <v-container>                  
                    <v-form>
                        <v-row>
                            <v-col cols="12">
                                <v-text-field
                                type="date"
                                    label="Ostatnia Płatność"
                                >

                                </v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field
                                    type="date"
                                    label="Przedłużono Do"
                                >

                                </v-text-field>
                            </v-col>  
                            <v-col cols="12">
                                <v-text-field
                                    type="number"
                                    label="Kwota"
                                >

                                </v-text-field>
                            </v-col>  
                            <v-col cols="12">
                                <v-select                                    
                                    label="Typ Płatości"
                                >

                                </v-select>
                            </v-col>
                            <v-col cols="12">
                                <v-textarea                                    
                                    label="Notatka"
                                >

                                </v-textarea>
                            </v-col>                                                                                                                   
                        </v-row>
                         
                    </v-form>
                    </v-container> 
                    <v-card-actions>
              <v-btn
                text                
                @click="dialog = false"
              >Anuluj</v-btn> 
               <v-spacer></v-spacer>
          <v-btn            
            text
            @click="savePayment()"
          >
            Zapisz
          </v-btn>     
                    </v-card-actions>                            
                </v-card>  
                   
          </v-dialog>            
    </v-container>
</template>
<script>
// import VuetifyGoogleAutocomplete from 'vuetify-google-autocomplete'
const dayjs = require('dayjs')
  export default {

    name:'Payments',    
    data: () => ({         
    address: "",
    action:'',
    warehouseName:'',
      dialog: false,
      invoiceDialog: false,
      historyDialog: false,
      headersHistory:[
        {
          text: 'Data',          
          sortable: false,
          value: 'date',
        }, 
        {
          text: 'Kwota',          
          sortable: false,
          value: 'price',
        },         
      ],
      headers: [
        {
          text: '#',          
          sortable: false,
          value: 'number',
        },         
        {
          text: 'Numer Umowy',
          align: 'start',
          sortable: false,
          value: 'dealNo',
        },    
        {
          text: 'Nazwisko',
          align: 'start',
          sortable: false,
          value: 'lastName',
        },   
        {
          text: 'Adres',
          align: 'start',
          sortable: false,
          value: 'address',
        },
        {
          text: 'Telefon',
          align: 'start',
          sortable: false,
          value: 'phone',
        }, 
        {
          text: 'Przedmiot',
          align: 'start',
          sortable: false,
          value: 'product',
        }, 
        {
          text: 'Ważne do',
          align: 'start',
          sortable: true,
          value: 'expirationDate',
        },                                                 
        { text: '', value: 'actions', sortable: false },         
      ],
      orders: [], 
      history:[]     
    }),

    created () {
      this.initialize()
    },

    methods: {     
      showDialog(id){
        console.log(id)
        this.dialog = true
      }, 
      showHistory(id){
        console.log(id)
        this.historyDialog = true
        this.history = [
          {
            date:dayjs().format('YYYY-MM-DD HH:mm'),        
            price:139,        
          },                                                          
        ]        
      },       
      showInvoice(id){
        console.log(id)
        this.invoiceDialog = true
      },
      initialize () {
        this.payments = [
          {
            id:1,
            number:1,
            dealNo:'BD010101',
            lastName:'Sowiński',
            address: 'Wojska Polskiego',
            phone:'519835159',
            product:'Łóżko',
            expirationDate:dayjs().format('YYYY-MM-DD HH:mm'),        
          },  
          {
            id:1,
            number:1,
            dealNo:'BD010101',
            lastName:'Sowiński',
            address: 'Wojska Polskiego',
            phone:'519835159',
            product:'Łóżko',
            expirationDate:dayjs().format('YYYY-MM-DD HH:mm'),        
          },  
          {
            id:1,
            number:1,
            dealNo:'BD010101',
            lastName:'Sowiński',
            address: 'Wojska Polskiego',
            phone:'519835159',
            product:'Łóżko',
            expirationDate:dayjs().format('YYYY-MM-DD HH:mm'),        
          },  
          {
            id:1,
            number:1,
            dealNo:'BD010101',
            lastName:'Sowiński',
            address: 'Wojska Polskiego',
            phone:'519835159',
            product:'Łóżko',
            expirationDate:dayjs().format('YYYY-MM-DD HH:mm'),        
          },  
          {
            id:1,
            number:1,
            dealNo:'BD010101',
            lastName:'Sowiński',
            address: 'Wojska Polskiego',
            phone:'519835159',
            product:'Łóżko',
            expirationDate:dayjs().format('YYYY-MM-DD HH:mm'),        
          },  
          {
            id:1,
            number:1,
            dealNo:'BD010101',
            lastName:'Sowiński',
            address: 'Wojska Polskiego',
            phone:'519835159',
            product:'Łóżko',
            expirationDate:dayjs().format('YYYY-MM-DD HH:mm'),        
          },  
          {
            id:1,
            number:1,
            dealNo:'BD010101',
            lastName:'Sowiński',
            address: 'Wojska Polskiego',
            phone:'519835159',
            product:'Łóżko',
            expirationDate:dayjs().format('YYYY-MM-DD HH:mm'),        
          },  
          {
            id:1,
            number:1,
            dealNo:'BD010101',
            lastName:'Sowiński',
            address: 'Wojska Polskiego',
            phone:'519835159',
            product:'Łóżko',
            expirationDate:dayjs().format('YYYY-MM-DD HH:mm'),        
          },  
          {
            id:1,
            number:1,
            dealNo:'BD010101',
            lastName:'Sowiński',
            address: 'Wojska Polskiego',
            phone:'519835159',
            product:'Łóżko',
            expirationDate:dayjs().format('YYYY-MM-DD HH:mm'),        
          },  
          {
            id:1,
            number:1,
            dealNo:'BD010101',
            lastName:'Sowiński',
            address: 'Wojska Polskiego',
            phone:'519835159',
            product:'Łóżko',
            expirationDate:dayjs().format('YYYY-MM-DD HH:mm'),        
          },  
          {
            id:1,
            number:1,
            dealNo:'BD010101',
            lastName:'Sowiński',
            address: 'Wojska Polskiego',
            phone:'519835159',
            product:'Łóżko',
            expirationDate:dayjs().format('YYYY-MM-DD HH:mm'),        
          },  
          {
            id:1,
            number:1,
            dealNo:'BD010101',
            lastName:'Sowiński',
            address: 'Wojska Polskiego',
            phone:'519835159',
            product:'Łóżko',
            expirationDate:dayjs().format('YYYY-MM-DD HH:mm'),        
          },  
          {
            id:1,
            number:1,
            dealNo:'BD010101',
            lastName:'Sowiński',
            address: 'Wojska Polskiego',
            phone:'519835159',
            product:'Łóżko',
            expirationDate:dayjs().format('YYYY-MM-DD HH:mm'),        
          },  
          {
            id:1,
            number:1,
            dealNo:'BD010101',
            lastName:'Sowiński',
            address: 'Wojska Polskiego',
            phone:'519835159',
            product:'Łóżko',
            expirationDate:dayjs().format('YYYY-MM-DD HH:mm'),        
          },  
          {
            id:1,
            number:1,
            dealNo:'BD010101',
            lastName:'Sowiński',
            address: 'Wojska Polskiego',
            phone:'519835159',
            product:'Łóżko',
            expirationDate:dayjs().format('YYYY-MM-DD HH:mm'),        
          },  
                    {
            id:1,
            number:1,
            dealNo:'BD010101',
            lastName:'Sowiński',
            address: 'Wojska Polskiego',
            phone:'519835159',
            product:'Łóżko',
            expirationDate:dayjs().format('YYYY-MM-DD HH:mm'),        
          },  
          {
            id:1,
            number:1,
            dealNo:'BD010101',
            lastName:'Sowiński',
            address: 'Wojska Polskiego',
            phone:'519835159',
            product:'Łóżko',
            expirationDate:dayjs().format('YYYY-MM-DD HH:mm'),        
          },  
          {
            id:1,
            number:1,
            dealNo:'BD010101',
            lastName:'Sowiński',
            address: 'Wojska Polskiego',
            phone:'519835159',
            product:'Łóżko',
            expirationDate:dayjs().format('YYYY-MM-DD HH:mm'),        
          },  
          {
            id:1,
            number:1,
            dealNo:'BD010101',
            lastName:'Sowiński',
            address: 'Wojska Polskiego',
            phone:'519835159',
            product:'Łóżko',
            expirationDate:dayjs().format('YYYY-MM-DD HH:mm'),        
          },  
          {
            id:1,
            number:1,
            dealNo:'BD010101',
            lastName:'Sowiński',
            address: 'Wojska Polskiego',
            phone:'519835159',
            product:'Łóżko',
            expirationDate:dayjs().format('YYYY-MM-DD HH:mm'),        
          },                                                                                                                                                                                                                                              
        ]
      },
    },
  }
</script>