<template>
  <v-app id="inspire">        
    <!-- <div id="nav" v-if="user=='true'">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div>
    <router-view/> -->
    <v-app-bar app v-if="user=='true'">
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

      <v-toolbar-title></v-toolbar-title>
    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      fixed
      temporary
      v-if="user=='true'"
    >
      <v-list>
        <v-list-item
          v-for="[icon, text, link] in links"
          :key="icon"
          :to="link"
        >
          <v-list-item-icon>
            <v-icon>{{ icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ text }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>    
      <!--  -->
    </v-navigation-drawer>              
    <v-content>
      <!-- <v-container class="fill-height" fluid> -->
        <router-view/>
      <!-- </v-container> -->
    </v-content>    
  </v-app>
</template>
<script>
export default {
   name: 'App',
    data: () => ({
    user: false,
      drawer: null,
      links: [
        ['mdi-calendar', 'Kalendarz', 'calendar'], 
        ['mdi-store-plus', 'Sklep Online', 'store'],  
        ['mdi-warehouse', 'Magazyn', 'warehouse'],         
        ['mdi-cash-clock', 'Płatności', 'payments'],         
        ['mdi-account-group', 'Klienci', 'clients'],
        ['mdi-receipt-text-check', 'Faktury', 'invoice'],    
        ['mdi-finance', 'Raporty', 'raports'],         
        ['mdi-calendar-today', 'Mój Dzień', 'day'],         
        ['mdi-bed', 'Sprzęt', 'service'],         
        // ['mdi-bell', 'Powiadomienia', 'notifications'],    
        ['mdi-account-cash', 'Raport dzienny', 'daily-raport'],      
        ['mdi-account', 'Użytkownicy', 'users'],         
      ],    
   }),
  mounted() {
    console.log(this.user)
    if (localStorage.getItem("user")=='true'){    
      console.log(localStorage.getItem("user"))   
      this.user =localStorage.getItem("user")
      console.log(this.user)
    }
  }
};
</script>

