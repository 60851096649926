<template>
    <v-container>        
            <v-row>
                <v-col cols="12">
                    <v-data-table
                        :headers="headers"
                        :items="warehouses"    
                        class="elevation-1"    
                        mobile-breakpoint="0"   
                    >
                        <template v-slot:top>
                        <v-toolbar
                            flat
                        >
                            <v-toolbar-title>Magazyn</v-toolbar-title>
                            <v-divider
                            class="mx-4"
                            inset
                            vertical
                            ></v-divider>
                            <v-spacer></v-spacer>  
                            <v-btn class="primary" @click="dialog=true, action='Tworzenie'">Dodaj Magazyn</v-btn>     
                        </v-toolbar>
                        </template>
        <template v-slot:item.actions="{ item }">
                        <v-btn class="primary" @click="getWarehouseData(item.id)">
      <v-icon
        small
        class="mr-2"
        
      >
        mdi-pencil
      </v-icon>
                        </v-btn>
    </template>   
        <template v-slot:item.details="{ item }">
            <v-btn class="info" @click="infoWarehouse(item.id)">
      <v-icon
        small
        class="mr-2"
        
      >
        mdi-eye
      </v-icon>
            </v-btn>
    </template>                          
                    </v-data-table>
                </v-col>
            </v-row>  
    <v-dialog
      v-model="dialog"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">{{action}} Magazynu</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-text-field
                 v-model="warehouseName"
                  label="Nazwa Magazynu"
                  required
                  placeholder="Nazwa Magazynu"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <vuetify-google-autocomplete
                    id="map"
                    required
                    append-icon="mdi-search"                    
                    classname="form-control"
                    placeholder="Adres Magazynu"
                    label="Adres Magazynu"
                    ref="addr"
                    v-on:placechanged="getAddressData"
                    country="pl"
                >
                </vuetify-google-autocomplete>
              </v-col>
            </v-row>
          </v-container>          
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="dialog = false"
          >
            Anuluj
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="saveWarehouse()"
          >
            Dodaj Magazyn
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>    
<v-dialog
      v-model="productsDialog"
        fullscreen
        hide-overlay
    >
      <v-card>
        <v-toolbar
          dark
          color="primary"
        >
          <v-btn
            icon
            dark
            @click="productsDialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Produkty w Magazynie - {{whName}}</v-toolbar-title>
          <v-spacer></v-spacer>

        </v-toolbar>
        <v-container>
        <v-form>
                    <!-- <v-sheet  color="grey lighten-3">    
                        <v-sheet
            :elevation="2"
            rounded>               -->
        <v-row  class="pa-5">               
          <v-col cols="4">
            <v-text-field
                label="Nazwa Produktu"
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field
                label="Ilość"
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-btn class="primary">Dodaj</v-btn>
          </v-col>   
                            
        </v-row>
                                <!-- </v-sheet>
                    </v-sheet>     -->
        </v-form>   
         <v-spacer></v-spacer>
         </v-container> 
                    <v-data-table
                        :headers="headersProducts"
                        :items="productsInWarehouse"    
                        class="elevation-1"    
                        mobile-breakpoint="0"   
                    >
        <template v-slot:item.qty="{ item }">
            <v-row>
                <v-col cols="6">
                    <v-text-field
                        :value="item.id"           
                    > 
                    </v-text-field>    
                </v-col> 
                 <v-col cols="6">      
            <v-btn class="primary" @click="saveProductQty(item.id)">
                <v-icon
                    small
                    class="pt-1"                    
                    
                >
                    mdi-pencil
                </v-icon>
            </v-btn>
                 </v-col>
            </v-row>
    </template>                     
                    </v-data-table>        
      </v-card>
    </v-dialog>   
    </v-container>                              
</template>
<script>
import VuetifyGoogleAutocomplete from 'vuetify-google-autocomplete'


  export default {
    name:'Warehouse',
    components:{VuetifyGoogleAutocomplete},
    data: () => ({
    calculate:true,
    whName: '',
    productsDialog:false,
    address: "",
    action:'',
    warehouseName:'',
      dialog: false,
      dialogDelete: false,
      headers: [
        {
          text: 'Nazwa Magazynu',
          align: 'start',
          sortable: false,
          value: 'name',
        },    
        {
          text: 'Adres Magazynu',
          align: 'start',
          sortable: false,
          value: 'address',
        },          
        { text: '', value: 'actions', sortable: false },
         { text: '', value: 'details', sortable: false },
      ],
           headersProducts: [
        {
          text: 'Nazwa Produktu',
          align: 'start',
          sortable: false,
          value: 'productName',
        },    
        {
          text: 'Dostępna ilość',
          align: 'start',
          sortable: false,
          value: 'qty',
        },          
      ], 
      warehouses: [],
      productsInWarehouse:[]
    }),

    created () {
      this.initialize()
    },

    methods: {
      getAddressData: function (addressData) {
        console.log(addressData)
        this.address = addressData;
        console.log(this.address)
      },
      getWarehouseData(item){
        console.log(item)
         this.dialog=true
         this.action = 'Edycja'        
      },
      infoWarehouse(item){
        console.log(item)
       this.productsDialog = true
       this.whName = 'Bydgoszcz'
        this.productsInWarehouse = [{
            id:1,
            productName:'asd',
            qty:150
        }]
      },              
      initialize () {
        this.warehouses = [
          {
            id:1,
            name: 'Bydgoszcz',
            address: 'Wojska Polskiego'
          },
          {
            id:1,
            name: 'Bydgoszcz',
            address: 'Wojska Polskiego'
          },
          {
            id:1,
            name: 'Bydgoszcz',
            address: 'Wojska Polskiego'
          },
          {
            id:1,
            name: 'Bydgoszcz',
            address: 'Wojska Polskiego'
          },
          {
            id:1,
            name: 'Bydgoszcz',
            address: 'Wojska Polskiego'
          },                                                
        ]
      },
      saveWarehouse(){
        console.log(this.warehouseName)
      }
    },
  }
</script>