<template>
    <v-container>
        <v-row >
             <v-col cols="12" style="height:650px;">
                <FullCalendar style="height:650px;" :options="calendarOptions" />   
             </v-col> 
        </v-row>
    </v-container>
</template>

<script>
import '@fullcalendar/core/vdom' // solves problem with Vite
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import timeGridPlugin from '@fullcalendar/timegrid';
import plLocale from '@fullcalendar/core/locales/pl';

export default {
   name: 'Calendar',
  components: {
    FullCalendar // make the <FullCalendar> tag available
  },
  data() {
    return {
      calendarOptions: {
        plugins: [ dayGridPlugin, interactionPlugin,timeGridPlugin ],        
            headerToolbar: {
                left: 'prev,today,next',
                center: 'title',
                right: 'dayGridMonth,timeGridWeek,timeGridDay,timeGridFourDay'
            },
            views: {
                timeGridFourDay: {
                    type: 'timeGrid',
                    duration: {
                        days: 3
                    },
                    buttonText: '3 Dniowy'
                }
            },
            businessHours: {
                daysOfWeek: [1, 2, 3, 4, 5, 6, 7], // Monday - Thursday
                // days of week. an array of zero-based day of week integers (0=Sunday)                
                startTime: '06:00', // a start time (10am in this example)
                endTime: '22:00', // an end time (6pm in this example)
            },  
            displayEventTime: false,
            lang: 'pl',
            selectable: true,
            longPressDelay: 750,
            locale: plLocale,
            buttonIcons: false, // show the prev/next text
            allDaySlot: false,
            weekNumbers: false,
            draggable: false,
            disableDragging: false,
            disableResizing: false,
            initialView: 'timeGridFourDay',
            navLinks: true, // can click day/week names to navigate views
            editable: true,
            dayMaxEvents: true, // allow "more" link when too many events                  
      }
    }
  }   
};
</script>
<style>
.fc{
    height: 650px !important;
}
.fc .fc-col-header-cell-cushion {
  display: inline-block;
  padding: 2px 4px;
}
.fc-toolbar-chunk{
    text-align: center;
}
.fc .fc-toolbar {
    display: block;
}
</style>
