<template>
    <v-container>        
            <v-row>
                <v-col cols="12">
                    <v-data-table
                        :headers="headers"
                        :items="users"    
                        class="elevation-1"    
                          mobile-breakpoint="0"   
                    >   
                                            <template v-slot:top>
                        <v-toolbar
                            flat
                        >
                            <v-toolbar-title>Użytkownicy</v-toolbar-title>
                            <v-divider
                            class="mx-4"
                            inset
                            vertical
                            ></v-divider>
                            <v-spacer></v-spacer>  
                            <v-btn class="primary" @click="dialog=true, action='Tworzenie'">Dodaj</v-btn>     
                        </v-toolbar>
                        </template>
                      <template v-slot:item.details="{ item }">
                        <v-btn class="warning" @click="infoUser(item.id)"><v-icon                     small
                    class="pt-1"  >mdi-account-edit</v-icon></v-btn>                                     
                      </template>                              
                      <template v-slot:item.deleteUser="{ item }">
                        <v-btn class="error" @click="deleteUser(item.id)"><v-icon                     small
                    class="pt-1"  >mdi-delete</v-icon></v-btn>                                     
                                                         
                      </template>                                                                               
                    </v-data-table>                      
                </v-col>
            </v-row>                       
    <v-dialog
      v-model="dialog"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">{{action}} Użytkownika</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-text-field                 
                  label="Imię"
                  required
                  placeholder="Imię"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-text-field                 
                  label="Nazwisko"
                  required
                  placeholder="Nazwisko"
                ></v-text-field>              
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-text-field                 
                  label="Login"
                  required
                  type="email"
                  placeholder="Login"
                ></v-text-field>              
              </v-col> 
              <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-text-field                 
                  label="Hasło"
                  required
                  type="password"                  
                ></v-text-field>              
              </v-col> 
              <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-select label="Magazyn"></v-select>           
              </v-col>                                          
            </v-row>
          </v-container>          
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="dialog = false"
          >
            Anuluj
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="saveWarehouse()"
          >
           Zapisz
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>  
    </v-container>                 
</template>
<script>
  export default {

    name:'Users',    
    data: () => ({         
      dialog: false,
      action:'',
      headers: [       
        {
          text: 'Imię',
          align: 'start',
          sortable: false,
          value: 'firstName',
        }, 
        {
          text: 'Nazwisko',
          align: 'start',
          sortable: false,
          value: 'lastName',
        }, 
        {
          text: 'Magazyn',
          align: 'start',
          sortable: false,
          value: 'warehouse',
        },                                               
        { text: '', value: 'details', sortable: false },         
        { text: '', value: 'deleteUser', sortable: false },         
      ],
      users: [],         
    }),

    created () {
      this.initialize()
    },

    methods: {     
      initialize () {
        this.users = [
          {
            id:1,            
            firstName:'Arek',
            lastName:'Sowiński',                              
            warehouse:'Bydgoszcz',                              
          }                                                                                                                                                                                                                                             
        ]
      },
      infoUser(){
        this.dialog = true
        this.action = 'Edycja'
      }
    },
  }
</script>