<template>
    <v-container>        
            <v-row>
                <v-col cols="12">  
                    <v-sheet  color="grey lighten-3">    
                        <v-sheet
            :elevation="2"
            rounded>              
                    <v-row class="pa-5">
                        <v-col cols="8">
                            <v-text-field
                                label="Numer Umowy"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="4">
                            <v-btn class="primary" style="width:100%"><v-icon>mdi-account-search</v-icon></v-btn>
                        </v-col>                          
                        <v-col cols="8">
                            <v-text-field
                                label="Nazwisko"
                            ></v-text-field>                            
                        </v-col>
                        <v-col cols="4">
                            <v-btn class="primary" style="width:100%"><v-icon>mdi-account-search</v-icon></v-btn>
                        </v-col>                          
                        <v-col cols="8">
                                                        <v-text-field
                                label="Adres"
                            ></v-text-field>  
                        </v-col>
                        <v-col cols="4">
                            <v-btn class="primary" style="width:100%"><v-icon>mdi-account-search</v-icon></v-btn>
                        </v-col>  
                        <v-col cols="12">
                            <v-select label="Produkt"></v-select>
                        </v-col> 
                        <v-col cols="12">
                            <v-btn class="primary" style="width:100%"><v-icon>mdi-account-search</v-icon></v-btn>
                        </v-col>                                                                                                                      
                    </v-row>    
                        </v-sheet>
                    </v-sheet>                
                </v-col>
                <v-spacer></v-spacer>
                <v-col cols="12">
                    <v-data-table
                     v-model="selected"
                        :headers="headers"
                        :items="rents"    
                        class="elevation-1"    
                        :single-select="singleSelect"
                          mobile-breakpoint="0"  
                             show-select
                          item-key="name" 
                    >    
                                            <template v-slot:top>                                                 
                                              <v-btn class="primary" @click='createMultipleReturn()'>Utwórz Odbiór Z Zaznaczonych</v-btn>                                        
                        <v-toolbar
                            flat
                        >
                            <v-toolbar-title>Klienci</v-toolbar-title>
                            <v-divider
                            class="mx-4"
                            inset
                            vertical
                            ></v-divider>
                            <v-spacer></v-spacer>                              
                        </v-toolbar>
                        </template>                              
                      <template v-slot:item.actions="{ item }">
                        <v-btn class="primary" @click="showDialog(item.id)"><v-icon                     small
                    class="pt-1"  >mdi-plus</v-icon></v-btn>
                        <!-- <v-btn class="warning" @click="showHistory(item.id)"><v-icon                     small
                    class="pt-1"  >mdi-history</v-icon></v-btn>                                         -->
                      </template>                                                           
                    </v-data-table>
                </v-col>
            </v-row>   
          <v-dialog fullscreen hide-overlay v-model="dialog">
            <v-card>
        <v-toolbar
          dark
          color="primary"
        >
          <v-btn
            icon
            dark
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Tworzenie Wydarzenia</v-toolbar-title>
          <v-spacer></v-spacer>

        </v-toolbar>    
        <v-container>          
            <v-row>
                <v-col cols="6">
                    <v-select></v-select>
                </v-col>
                <v-col cols="6">
                    <v-select></v-select>
                </v-col>
            </v-row>            
            <v-row >
                <v-col cols="12" style="height:650px;">
                    <FullCalendar v-if="calendar" style="height:650px;" :options="calendarOptions"  />   
                </v-col> 
            </v-row>  
        </v-container>
            </v-card>          
          </v-dialog>    
    <v-dialog
      v-model="dialogCreate"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Dodawanie Wydarzenia</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="6"
              >
                <v-text-field
                  type="datetime-local"
                  label="Data rozpoczęcia"
                  required
                ></v-text-field>
              </v-col>
              <v-col
                cols="6"
              >
                <v-text-field
                  type="datetime-local"
                  label="Data zakończenia"
                  required
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
              >
              <v-select :items="EventTypes" label="Typ wydarzenia" @change="select_value"></v-select>
              </v-col>
              <v-col
                cols="12"
                v-if="currentEvent=='Transport'"
              >
                <v-text-field                
                  label="Telefon Dzwoniącego"
                  required
                ></v-text-field  >
              </v-col>   
              <v-col
                cols="12"
                v-if="currentEvent=='Transport'"
              >
                <v-select v-model="selectedproducts" :items="itemsToReturn" attach label="Przedmiot którego dotyczy transport" multiple>
                  <template v-slot:prepend-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-text-field v-model="searchTerm" placeholder="Szukaj" @input="searchItems"></v-text-field>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-2"></v-divider>
                  </template>
                </v-select>
              </v-col> 
              <v-col
                cols="12"
                v-if="currentEvent=='Transport'"
              >
                <vuetify-google-autocomplete
                    id="map"
                    required
                    append-icon="mdi-search"                    
                    classname="form-control"
                    placeholder="Adres Tranportu"
                    label="Adres Tranportu"
                    ref="addr"
                    v-on:placechanged="getAddressData"
                    country="pl"
                >
                </vuetify-google-autocomplete>
              </v-col>  
              <v-col
                cols="12"
                v-if="currentEvent=='Transport'"
              >
                <v-text-field                
                  label="Ilość KM"
                  required
                ></v-text-field  >
              </v-col>               
              <v-col
                cols="12"
                v-if="currentEvent=='Transport'"
              >
                <v-text-field                
                  label="Kwota Transportu"
                  required
                ></v-text-field  >
              </v-col> 
              <v-col
                cols="12"
                v-if="currentEvent=='Transport'"
              >
                <v-select
                
                  label="Status Płatności"
                  required
                ></v-select>
              </v-col> 
              <v-col
                cols="12"
                v-if="currentEvent=='Transport'"
              >
                <v-select
                
                  label="Rodzaj Płatności"
                  required
                ></v-select>
              </v-col>                                                      
              <v-col
                cols="12"
                v-if="currentEvent=='Serwis'"
              >
                <v-text-field                
                  label="Telefon Dzwoniącego"
                  required
                ></v-text-field  >
              </v-col>   
              <v-col
                cols="12"
                v-if="currentEvent=='Serwis'"
              >
                <v-select v-model="selectedproducts" :items="itemsToReturn" attach label="Przedmiot którego dotyczy serwis">
                  <template v-slot:prepend-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-text-field v-model="searchTerm" placeholder="Szukaj" @input="searchItems"></v-text-field>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-2"></v-divider>
                  </template>
                </v-select>
              </v-col>                                        
              <v-col
                cols="12"
                v-if="currentEvent=='Odbiór'"
              >
                <v-select                
                  label="Rodzaj Odbioru Z/O"
                  required
                ></v-select>
              </v-col>
              <v-col
                cols="12"
                v-if="currentEvent=='Odbiór'"
              >
                <v-text-field                
                  label="Telefon Wypożyczającego"
                  required
                ></v-text-field  >
              </v-col>  
              <v-col
                cols="12"
                v-if="currentEvent=='Odbiór'"
              >
                <v-text-field                
                  label="Telefon do Kontaktu"
                  required
                ></v-text-field  >
              </v-col>                             
              <v-col
                cols="12"
                v-if="currentEvent=='Odbiór'"
              >
                <v-select v-model="selectedproducts" :items="itemsToReturn" attach label="Przedmioty Do Odbioru" multiple>
                  <template v-slot:prepend-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-text-field v-model="searchTerm" placeholder="Szukaj" @input="searchItems"></v-text-field>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-2"></v-divider>
                  </template>
                </v-select>
              </v-col>    
              <v-col
                cols="12"
                v-if="currentEvent=='Odbiór'"
              >
                <v-text-field                
                  label="Kwota Odbioru"
                  required
                ></v-text-field  >
              </v-col> 
              <v-col
                cols="12"
                v-if="currentEvent=='Odbiór'"
              >
                <v-select
                
                  label="Status Płatności"
                  required
                ></v-select>
              </v-col> 
              <v-col
                cols="12"
                v-if="currentEvent=='Odbiór'"
              >
                <v-select
                
                  label="Rodzaj Płatności"
                  required
                ></v-select>
              </v-col>                                                     
              <v-col
                cols="12"
                v-if="currentEvent=='Inne'"
              >
                <v-text-field
                
                  label="Tytuł"
                  required
                ></v-text-field>
              </v-col>  
              <v-col
                cols="12"
                v-if="currentEvent=='Inne'"
              >
                <v-text-field
                
                  label="Kupno"
                  required
                ></v-text-field>
              </v-col>  
              <v-col
                cols="12"
                v-if="currentEvent=='Inne'"
              >
                <v-text-field
                
                  label="Sprzedaż"
                  required
                ></v-text-field>
              </v-col>     
              <v-col
                cols="12"
                v-if="currentEvent=='Inne'"
              >
                <v-select
                
                  label="Status Płatności"
                  required
                ></v-select>
              </v-col> 
              <v-col
                cols="12"
                v-if="currentEvent=='Inne'"
              >
                <v-select
                
                  label="Rodzaj Płatności"
                  required
                ></v-select>
              </v-col>                                                                 
              <v-col
                cols="12"
                v-if="currentEvent=='Wypozyczenie'"
              >
                <v-select
                
                  label="Rodzaj Wypozyczenia"
                  required
                ></v-select>
              </v-col>
              <v-col
                cols="12"
                v-if="currentEvent=='Wypozyczenie'"
              >
                <v-text-field
                  
                  label="Telefon Wypozyczającego"
                  required
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                v-if="currentEvent=='Wypozyczenie'"
              >
                <v-text-field
                  
                  label="Telefon Dzwoniącego"
                  required
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                v-if="currentEvent=='Wypozyczenie'"
              >
                <vuetify-google-autocomplete
                    id="map"
                    required
                    append-icon="mdi-search"                    
                    classname="form-control"
                    placeholder="Adres Dostawy"
                    label="Adres Dostawy"
                    ref="addr"
                    v-on:placechanged="getAddressData"
                    country="pl"
                >
                </vuetify-google-autocomplete>
              </v-col>
                          <v-col
                          v-if="currentEvent=='Wypozyczenie'"
                cols="12"
              >
                <v-text-field
                  
                  label="Ilość Kilometrów"
                  required
                ></v-text-field>
              </v-col>
                          <v-col
                          v-if="currentEvent=='Wypozyczenie'"
                cols="12"
              >
                <v-text-field
                  
                  label="Stawka za KM"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" v-if="currentEvent=='Wypozyczenie'">
                
                <h3>Produkty</h3>
<v-list
      subheader
      two-line
    >

      <v-list-item
        v-for="product in products"
        :key="product.title"
      >

        <v-list-item-content>
          <v-list-item-title v-text="product.title"></v-list-item-title>
        <v-list-item-title class="color:green;" v-text="product.subtitle"></v-list-item-title>
        </v-list-item-content>

        <v-list-item-action>
          <v-btn icon>
            <v-icon color="green lighten-1">mdi-plus</v-icon>
          </v-btn>
          </v-list-item-action>
          <v-list-item-action class="text-center">
                  <v-list-item-title>
            {{product.qty}}
          </v-list-item-title>
          </v-list-item-action>
          <v-list-item-action>
                  <v-btn icon>
            <v-icon color="red lighten-1">mdi-minus</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
    </v-list>                
              </v-col>
              <v-col
                cols="12"
              >
                <v-textarea
                  
                  label="Notatka"
                  required
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="dialogCreate = false"
          >
            Anuluj
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="dialogCreate = false"
          >
            Zapisz
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-menu
          v-model="quickDetailsDialog"
          :close-on-content-click="false"
          style="width:100%;"
        >
          <v-card
            color="grey lighten-4"
            flat
          >
            <v-toolbar
              color="green"
              dark
            >
              <v-btn icon @click="handleShowExpandedDetails()">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-toolbar-title>Szczegóły Wydarzenia</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-container>
                <v-sheet color="grey" rounded="rounded" class="mx-auto">
                    Numer Umowy<br>
                    Adres Dostawy/Wypożyczającego<br>
                    Telefon Wypożyczającego<br>
                    Uwagi
                </v-sheet>
            <v-row>
              <v-col
                cols="6"
              >
                <v-text-field
                  type="datetime-local"
                  label="Data rozpoczęcia"
                  required
                ></v-text-field>
              </v-col>
              <v-col
                cols="6"
              >
                <v-text-field
                  type="datetime-local"
                  label="Data zakończenia"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-select label="Pracownik"></v-select>
              </v-col>
            </v-row>
            </v-container>
            <v-card-actions>
              <v-btn
                text
                color="secondary"
                @click="quickDetailsDialog = false"
              >
                Anuluj
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
        <v-dialog  transition="dialog-bottom-transition" fullscreen hide-overlay v-model="fullDetailsDialog">
                   <v-toolbar
          dark
          color="primary"
        >
          <v-btn
            icon
            dark
            @click="fullDetailsDialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Szczegóły</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
            <v-card>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-sheet color="grey" rounded="rounded" class="mx-auto">
                        Numer Umowy<br>
                        Adres Dostawy/Wypożyczającego<br>
                        Telefon Wypożyczającego<br>
                        Uwagi
                        Produkty do Odbioru/Serwisu/Transportu
                    </v-sheet>   
                  </v-col>
                </v-row>
              </v-container>           
                  <v-stepper v-model="e1">
                        <v-stepper-header>
                        <v-stepper-step     
                        editable                       
                            step="1"
                        >
                            Dane Klienta
                        </v-stepper-step>

                        <v-divider></v-divider>

                        <v-stepper-step
                            editable
                            step="2"
                        >
                            Produkty
                        </v-stepper-step>

                        <v-divider></v-divider>

                        <v-stepper-step step="3"  editable>
                         
                            Płatność
                        </v-stepper-step>
                        <v-stepper-step step="4"  editable>
                         
                            Formularz Odbioru
                        </v-stepper-step>  
                        <v-stepper-step step="5"  editable>
                         
                            Formularz Transportu
                        </v-stepper-step> 
                        <v-stepper-step step="6"  editable>
                         
                            Formularz Serwisu
                        </v-stepper-step>
                        <v-stepper-step step="7"  editable>
                         
                            Formularz Inne
                        </v-stepper-step>                                                                                                
                        </v-stepper-header>

                        <v-stepper-items>
                          <v-stepper-content step="7">
                            <v-form>
                              <v-row>                                 
                                <v-col cols="12">
                                  <v-text-field label="Zysk Pracownika"></v-text-field>
                                </v-col>                              
                                <v-col cols="12">
                                  <v-text-field label="Koszt Pracownika"></v-text-field>
                                </v-col>                                      
                                    <v-col cols="12">
                                      <v-textarea label="Notatka Pracownika">

                                      </v-textarea>
                                    </v-col>                                                                                                                                                           
                              </v-row>
                            </v-form>
                          </v-stepper-content> 
                          <v-stepper-content step="4">
                            <v-form>
                              <v-row>                                 
                                <v-col cols="12">
                                  <v-text-field label="Ilość KM"></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                  <v-text-field label="Cena za KM"></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                  <v-text-field label="Dodatkowy koszt Odbioru"></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                  <v-text-field label="Całkowity Koszt Odbioru"></v-text-field>
                                </v-col>   
                                <v-col cols="12">
                                  <h3>Produkty do Odbioru</h3>
                              <v-list
                                    subheader
                                    two-line
                                  >

                                    <v-list-item
                                      v-for="product in products"
                                      :key="product.title"
                                    >

                                      <v-list-item-content>
                                        <v-list-item-title v-text="product.title"></v-list-item-title> <br>                                                                          
                                      </v-list-item-content>                   
                                        <v-list-item-action>
                                                <v-btn icon class="green">
                                          <v-icon class="color:black;">mdi-plus</v-icon>
                                        </v-btn>
                                      </v-list-item-action>
                                    </v-list-item>
                                  </v-list>      
                                </v-col>                                                                                      
                                <v-col cols="12">
                                  <v-select label="Status Płatności"></v-select>
                                </v-col>                                        
                                <v-col cols="12">
                                  <v-select label="Rodzaj Płatności"></v-select>
                                </v-col>      
                                    <v-col cols="12">
                                      <v-textarea label="Notatka Pracownika">

                                      </v-textarea>
                                    </v-col>                                                                                                                                                           
                              </v-row>
                            </v-form>
                          </v-stepper-content>                                                     
                          <v-stepper-content step="6">
                            <v-form>
                              <v-row>                                 
                                <v-col cols="12">
                                  <v-text-field label="Ilość KM"></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                  <v-text-field label="Cena za KM"></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                  <v-text-field label="Koszt Serwisu"></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                  <v-select label="Typ Serwisu"></v-select>
                                </v-col>                                 
                                <v-col cols="12">
                                  <v-text-field label="Koszt Całkowity"></v-text-field>
                                </v-col>                                 
                                <v-col cols="12">
                                  <v-select label="Status Płatności"></v-select>
                                </v-col>                                        
                                <v-col cols="12">
                                  <v-select label="Rodzaj Płatności"></v-select>
                                </v-col>      
                                    <v-col cols="12">
                                      <v-textarea label="Notatka Pracownika">

                                      </v-textarea>
                                    </v-col>                                                                                                                                                           
                              </v-row>
                            </v-form>
                          </v-stepper-content>                          
                          <v-stepper-content step="5">
                            <v-form>
                              <v-row>
                                    <v-col cols="12">
                                      <vuetify-google-autocomplete
                                          id="map"
                                          required
                                          append-icon="mdi-search"                    
                                          classname="form-control"
                                          placeholder="Adres Transportu"
                                          label="Adres Transportu"
                                          ref="addr"
                                          v-on:placechanged="getAddressData"
                                          country="pl"
                                      >
                                      </vuetify-google-autocomplete>                                      
                                    </v-col>                                
                                <v-col cols="12">
                                  <v-text-field label="Koszt Transportu"></v-text-field>
                                </v-col> 
                                <v-col cols="12">
                                  <v-select label="Status Płatności"></v-select>
                                </v-col>                                        
                                <v-col cols="12">
                                  <v-select label="Rodzaj Płatności"></v-select>
                                </v-col>   
                                    <v-col cols="12">
                                      <v-textarea label="Notatka Pracownika">

                                      </v-textarea>
                                    </v-col>                                                                                                                                                              
                              </v-row>
                            </v-form>
                          </v-stepper-content>
                        <v-stepper-content step="1">
                            <v-card
                            class="mb-12"                                                        
                            >                              
                                <v-form>
                                  <v-row>
                                    <v-col cols="12">
                                      <v-text-field label="Numer Umowy">

                                      </v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                      <v-text-field label="Imię Wypożyczającego">

                                      </v-text-field>
                                    </v-col> 
                                    <v-col cols="12">
                                      <v-text-field label="Nazwisko Wypożyczającego">

                                      </v-text-field>
                                    </v-col>  
                                    <v-col cols="12">
                                      <vuetify-google-autocomplete
                                          id="map"
                                          required
                                          append-icon="mdi-search"                    
                                          classname="form-control"
                                          placeholder="Adres Zameldowania"
                                          label="Adres Zameldowania"
                                          ref="addr"
                                          v-on:placechanged="getAddressData"
                                          country="pl"
                                      >
                                      </vuetify-google-autocomplete>                                      
                                    </v-col> 
                                    <v-col cols="12">
                                      <vuetify-google-autocomplete
                                          id="map"
                                          required
                                          append-icon="mdi-search"                    
                                          classname="form-control"
                                          placeholder="Adres Sprzętu"
                                          label="Adres Sprzętu"
                                          ref="addr"
                                          v-on:placechanged="getAddressData"
                                          country="pl"
                                      >
                                      </vuetify-google-autocomplete>                                      
                                    </v-col>   
                                    <v-col cols="12">
                                      <v-text-field label="Telefon Zgłaszającego">

                                      </v-text-field>
                                    </v-col> 
                                    <v-col cols="12">
                                      <v-text-field label="Telefon Wypożyczającego">

                                      </v-text-field>
                                    </v-col> 
                                    <v-col cols="12">
                                      <v-text-field label="E-mail">

                                      </v-text-field>
                                    </v-col>  
                                    <v-col cols="12">
                                      <v-textarea label="Notatka Pracownika">

                                      </v-textarea>
                                    </v-col>                                                                                                                                                                                                                                                                                             
                                  </v-row>
                                </v-form>                              

                            </v-card>

                            <v-btn
                            color="primary"
                            @click="e1 = 2"
                            >
                            Zapisz
                            </v-btn>
                        </v-stepper-content>

                        <v-stepper-content step="2">
                            <v-card
                            class="mb-12"
                            color="lighten-1"                            
                            >
                              <v-list
                                    subheader
                                    two-line
                                  >

                                    <v-list-item
                                      v-for="product in products"
                                      :key="product.title"
                                    >

                                      <v-list-item-content>
                                        <v-list-item-title v-text="product.title"></v-list-item-title> <br>
                                        <v-row>
                                          <v-col cols="6">
                                            <v-text-field label="Wypożyczono Do" type="date"></v-text-field>
                                          </v-col>
                                          <v-col cols="4">
                                            <v-text-field label="Kwota" type="number "></v-text-field>
                                          </v-col>                                          
                                          <v-col cols="2">
                                             <v-text-field label="Zdjęcie" type="file"></v-text-field>
                                          </v-col>                                          
                                        </v-row>
                                     
                                     
                                      </v-list-item-content>                   
                                        <v-list-item-action>
                                                <v-btn icon class="error">
                                          <v-icon class="color:black;">mdi-delete</v-icon>
                                        </v-btn>
                                      </v-list-item-action>
                                    </v-list-item>
                                  </v-list>                              
                            </v-card>

                            <v-btn
                            color="primary"
                            @click="e1 = 3"
                            >
                            Zapisz
                            </v-btn>

                        </v-stepper-content>

                        <v-stepper-content step="3">
                            <v-card
                            class="mb-12"
                            color="lighten-1"                            
                            >
                            <v-form>
                              <v-row>
                                <v-col cols="12">
                                  <v-text-field label="Ilość KM"></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                  <v-text-field label="Cena za KM"></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                  <v-text-field label="Do Zapłaty"></v-text-field>
                                </v-col> 
                                <v-col cols="12">
                                  <v-select label="Status Płatności"></v-select>
                                </v-col>                                        
                                <v-col cols="12">
                                  <v-select label="Rodzaj Płatności"></v-select>
                                </v-col>                                                                                                                                
                              </v-row>
                            </v-form>
                            </v-card>

                            <v-btn
                            color="primary"
                            @click="e1 = 1"
                            >
                            Zapisz
                            </v-btn>

                        </v-stepper-content>
                        </v-stepper-items>
                    </v-stepper>
            </v-card>
        </v-dialog>                                               
    </v-container>
</template>
<script>
import Swal from 'sweetalert2'

import '@fullcalendar/core/vdom' // solves problem with Vite
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import timeGridPlugin from '@fullcalendar/timegrid';
import plLocale from '@fullcalendar/core/locales/pl';
import VuetifyGoogleAutocomplete from 'vuetify-google-autocomplete'
const dayjs = require('dayjs')
  export default {
    components:{FullCalendar,VuetifyGoogleAutocomplete},
    name:'Clients',    
    data: () => ({   
      calendar:false,
      EventTypes: ['Inne','Wypozyczenie','Odbiór','Serwis','Transport'],
      itemsToReturn:['Łóżko - BD 12345 - 19.08.2022','Łóżko - BD 12345 - 20.08.2022'],
      currentEvent:'',
        e1: 1,
      quickDetailsDialog:false,
      dialogCreate:false,
      fullDetailsDialog:false,
      products:[{
        title:'Produkt1',
        subtitle:'Dostepny',
        qty:1
      }],        
      calendarOptions: {},             
      dialog: false,
        singleSelect: false,
        selected: [],      
      headers: [       
        {
          text: 'Numer Umowy',
          align: 'start',
          sortable: false,
          value: 'dealNo',
        }, 
        {
          text: 'Telefon',
          align: 'start',
          sortable: false,
          value: 'phone',
        }, 
        {
          text: 'Przedmiot',
          align: 'start',
          sortable: false,
          value: 'product',
        }, 
        {
          text: 'Ważne do',
          align: 'start',
          sortable: true,
          value: 'expirationDate',
        },                                                 
        { text: '', value: 'actions', sortable: false },         
      ],
      rents: [],         
    }),

    created () {
      this.initialize()
    },

    methods: {  
    searchItems() {
      if (!this.searchTerm) {
        this.products = this.productsCopy;
      }

      this.products = this.productsCopy.filter((fruit) => {
        return fruit.toLowerCase().indexOf(this.searchTerm.toLowerCase()) > -1;
      });
    },     
      select_value(e) {
        this.currentEvent = e
      },
      getAddressData: function (addressData) {
        this.address = addressData;
      },
    handleEventClick() {
        
        this.quickDetailsDialog = true
    },
    handleShowExpandedDetails(){

        this.fullDetailsDialog = true;
    },  
    handleEventDrop(data){
        console.log(data)
        Swal.fire({
        title: 'Napewno Zmienić Datę i Godzinę',
        text: "",
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Tak',
        cancelButtonText: 'Anuluj'
        }).then((result) => {
        if (result.isConfirmed) {
            console.log('confirmed')
        }
        })        
    },
    createMultipleReturn(){
      this.dialog = true
      this.calendar = true
       this.initCalendar()  
       setTimeout(()=>{document.querySelector('.fc-timeGridFourDay-button').click();console.log('click')},500)
      
    },
    initCalendar(){
        this.calendarOptions = {
        plugins: [ dayGridPlugin, interactionPlugin,timeGridPlugin ],        
            headerToolbar: {
                left: 'prev,today,next',
                center: 'title',
                right: 'dayGridMonth,timeGridWeek,timeGridDay,timeGridFourDay'
            },        
        events: [
          { title: 'event 1', date: '2022-08-18 10:00' },
          { title: 'event 2', date: '2022-08-18 15:00' }
        ],            
            views: {
                timeGridFourDay: {
                    type: 'timeGrid',
                    duration: {
                        days: 3
                    },
                    buttonText: '3 Dniowy'
                }
            },
            businessHours: {
                daysOfWeek: [1, 2, 3, 4, 5, 6, 7], // Monday - Thursday
                // days of week. an array of zero-based day of week integers (0=Sunday)                
                startTime: '06:00', // a start time (10am in this example)
                endTime: '22:00', // an end time (6pm in this example)
            },  
            displayEventTime: false,            
            selectable: true,
            longPressDelay: 750,
            locale: plLocale,
            buttonIcons: false, // show the prev/next text
            allDaySlot: false,
            weekNumbers: false,
            draggable: false,
            disableDragging: false,
            disableResizing: false,
            initialView: 'timeGridFourDay',
            navLinks: true, // can click day/week names to navigate views
            editable: true,
            dayMaxEvents: true, // allow "more" link when too many events    
            select: this.handleDateSelect,
            eventClick: this.handleEventClick,
            eventDrop:this.handleEventDrop           
        } 
    },
    handleDateSelect(selectInfo) {
        console.log(selectInfo)
        this.dialogCreate = true
    },            
      showDialog(id){
        console.log(id)
        this.dialog = true  
        this.calendar = true   
        this.initCalendar()  
        setTimeout(()=>{document.querySelector('.fc-timeGridFourDay-button').click();console.log('click')},500)
      }, 
      showHistory(id){
        console.log(id)
        this.historyDialog = true
        this.history = [
          {
            date:dayjs().format('YYYY-MM-DD HH:mm'),        
            price:139,        
          },                                                          
        ]        
      },       
      showInvoice(id){
        console.log(id)
        this.invoiceDialog = true
      },
      initialize () {
        this.rents = [
          {
            id:1,            
            dealNo:'BD010101',
            phone:'519835159',
            product:'Łóżko',
            expirationDate:dayjs().format('YYYY-MM-DD HH:mm'),        
          }                                                                                                                                                                                                                                             
        ]
      },
    },
  }
</script>
<style>
.fc{
    height: 650px !important;
}
.fc .fc-col-header-cell-cushion {
  display: inline-block;
  padding: 2px 4px;
}
.fc-toolbar-chunk{
    text-align: center;
}
.fc .fc-toolbar {
    display: block;
}
</style>