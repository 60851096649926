<template>
    <v-container>
        <v-row>
            <v-col cols="6">
                <v-text-field type="date" label="Data początkowa"></v-text-field>
            </v-col>
            <v-col cols="6">
                <v-text-field type="date" label="Data końcowa"></v-text-field>
            </v-col>             
            <v-col cols="6">
                <v-select></v-select>
            </v-col>
            <v-col cols="6">
                <v-select></v-select>
            </v-col>
            <v-col cols="12">
                <v-btn class="primary" style="width:100%;">Pokaż</v-btn>
            </v-col>                       
        </v-row>
      <v-row>
        <v-col cols="12">
  <v-card
    class="mx-auto text-center"
    color="green"
    dark    
  >      
  <v-card-text>      
    <v-list subheader>
      <v-subheader>Podsumowanie</v-subheader>

      <v-list-item
        v-for="raport in raports"
        :key="raport.title"
      >
        <v-list-item-content>
          <v-list-item-title v-text="raport.title"></v-list-item-title>
        </v-list-item-content>

        <v-list-item-content>
          <v-list-item-title v-text="raport.numbers"></v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>   
  </v-card-text>  
  <v-divider></v-divider>    
  </v-card>   
  
  <v-card
    class="mx-auto text-center"
    color="green"
    dark    
  >
    <v-card-text>
      <v-sheet color="rgba(0, 0, 0, .12)">
        <v-sparkline
          :value="value"
          color="rgba(255, 255, 255, .7)"
          height="100"
          padding="24"
          stroke-linecap="round"
          smooth
        >
          <template v-slot:label="item">
            ${{ item.value }}
          </template>
        </v-sparkline>
      </v-sheet>
    </v-card-text>

    <v-card-text>
      <div class="text-h4 font-weight-thin">
       Sprzedaż
      </div>
    </v-card-text>

    <v-divider></v-divider>
  </v-card>
        </v-col>
        <v-col cols="12">
  <v-card
    class="mx-auto text-center"
    color="red"
    dark    
  >
    <v-card-text>
      <v-sheet color="rgba(0, 0, 0, .12)">
        <v-sparkline
          :value="value"
          color="rgba(255, 255, 255, .7)"
          height="100"
          padding="24"
          stroke-linecap="round"
          smooth
        >
          <template v-slot:label="item">
            ${{ item.value }}
          </template>
        </v-sparkline>
      </v-sheet>
    </v-card-text>

    <v-card-text>
      <div class="text-h4 font-weight-thin">
       Wydatki
      </div>
    </v-card-text>

    <v-divider></v-divider>
  </v-card>
        </v-col>        
      </v-row>
    </v-container>
</template>
<script>
  export default {

    name:'Raports',    
    data: () => ({  
      value: [
        423,
        446,
        675,
        510,
        590,
        610,
        760,
      ],   
      raports: [
        {
          numbers: 150,          
          title: 'Zysk',
        },
        {
          numbers: 150,          
          title: 'Wydatki',
        },
        {
          numbers: 150,          
          title: 'Koszt',
        }, 
        {
          numbers: 150,          
          title: 'Ilość Godzin',
        },
        {
          numbers: 150,          
          title: 'Ilość Nadgodzin',
        },                                
      ],                    
    }),

    created () {
      this.initialize()
    },

    methods: {     
      initialize () {
      },
    },
  }
</script>