<template>
<v-container fluid fill-height>
            <v-layout align-center justify-center>
               <v-flex xs12 sm8 md4>
                  <v-card class="elevation-12">
                     <v-toolbar dark color="primary">
                        <v-toolbar-title>MedApp</v-toolbar-title>
                     </v-toolbar>
                     <v-card-text>
                        <v-form>
                           <v-text-field
                              prepend-icon="mdi-account"
                              name="login"
                              label="E-mail"
                              type="text"
                           ></v-text-field>
                           <v-text-field
                              id="password"
                              prepend-icon="mdi-lock"
                              name="password"
                              label="Hasło"
                              type="password"
                           ></v-text-field>
                        </v-form>
                     </v-card-text>
                     <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" @click="login()">Zaloguj</v-btn>
                     </v-card-actions>
                  </v-card>
               </v-flex>
            </v-layout>
</v-container>
</template>

<script>
export default {
   name: 'Login',
   props: {
      source: String,
   },
   methods:{
    async login(){
         console.log('click login')
        localStorage.setItem('user',true)
        window.location.href = '/'
       
    }
   }
};
</script>
