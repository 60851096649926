<template>    
<v-container>
            <v-row>
                        <v-toolbar
                            flat
                        >
                            <v-toolbar-title>Raport Dzienny</v-toolbar-title>
                            <v-divider
                            class="mx-4"
                            inset
                            vertical
                            ></v-divider>  
                             <v-spacer></v-spacer>                                                    
                        </v-toolbar>                  
                <v-col cols="12">
                        <v-form>
                            <v-row> 
                                 <v-col cols="12">
                                    <v-text-field
                                        label="Data"
                                        type="date"
                                    ></v-text-field>
                                </v-col> 
                                <v-col cols="12">
                                    <v-text-field
                                        label="Ilość Godzin"                                        
                                    ></v-text-field>                                                                 
                                </v-col> 
                                <v-col cols="12">
                                    <v-text-field
                                        label="Ilość Nadgodzin"                                        
                                    ></v-text-field>                                                                 
                                </v-col>                                                                                                
                                <v-col cols="12">
                                    <v-text-field
                                        label="Koszt Pracownika"                                        
                                    ></v-text-field>                                                                 
                                </v-col>
                                <v-col cols="12">
                                     <v-text-field
                                        label="Zysk Pracownika"                                        
                                    ></v-text-field>                                                                 
                                </v-col> 
                                <v-col cols="12">
                                     <v-text-field
                                        label="Wydatki służbowe"                                        
                                    ></v-text-field>                                                                 
                                </v-col>   
                                <v-col cols="12">
                                     <v-btn
                                        class="primary" style="width:100%;"
                                    >Zapisz</v-btn>                                                                 
                                </v-col>                                                                                                                                                                    
                            </v-row>
                        </v-form>                    
                </v-col>                
   
            </v-row> 
            </v-container>                     
</template>
<script>
  export default {

    name:'DayRaport',    
    data: () => ({           
    }),

    created () {
      this.initialize()
    },

    methods: {     
      initialize () {
      },
    },
  }
</script>