<template>
    <v-container>        
            <v-row>
                <v-col cols="12">
                    <v-data-table
                        :headers="headers"
                        :items="invoices"    
                        class="elevation-1"    
                         
                    >   
                                            <template v-slot:top>
                        <v-toolbar
                            flat
                        >
                            <v-toolbar-title>Faktury do Wystawienia</v-toolbar-title>
                            <v-divider
                            class="mx-4"
                            inset
                            vertical
                            ></v-divider>
                            <v-spacer></v-spacer>                              
                        </v-toolbar>
                        </template>                                
                      <template v-slot:item.actions="{ item }">
                        <v-btn class="primary" @click="makeDone(item.id)"><v-icon                     small
                    class="pt-1"  >mdi-check</v-icon></v-btn>
                      </template>                                   
                    </v-data-table>
                </v-col>
            </v-row>                                     
    </v-container>
</template>
<script>
const dayjs = require('dayjs')
  export default {
    name:'Invoice',    
    data: () => ({            
      headers: [
        {
          text: 'Data Dodania Płatności',          
          sortable: false,
          value: 'addDate',
        },         
        {
          text: 'Dane do Faktury',
          align: 'start',
          sortable: true,
          value: 'invoiceData',
        },                                   
        { text: '', value: 'actions', sortable: false },         
      ],
      orders: [],      
    }),

    created () {
      this.initialize()
    },

    methods: {     
      showCalendar(id){
        console.log(id)
        this.dialog = true
      }, 
      initialize () {
        this.invoices = [
          {
            id:1,
            addDate:dayjs().format('YYYY-MM-DD HH:mm'),
            invoiceData:'40620',           
          },   
          {
            id:1,
            addDate:dayjs().format('YYYY-MM-DD HH:mm'),
            invoiceData:'40620',           
          },                                                        
        ]
      },
    },
  }
</script>